import React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import Layout from '../../components/layout';
import Seo from '../../components/Seo';

const InteriorPage = ({ location }) => {

  const image = 'https://rfallp.com/images/assets/images/GettyImages-169944220.jpg';

  return (
    <Layout location={location}>
      <div className="page-wrapper-inner">
        <Seo
          title="Services | Income Tax Planning and Compliance"
          image={image}
          slug={location.pathname}
          description="Our experience and proactive approach to tax planning will help minimize your tax burden and achieve your goals."
        />
        {/* Main */}
        <section className="wrapper style1">
          <div className="container">
            <div className="row gtr-200">
              <div className="col-4 col-12-narrower">
                <div id="sidebar">

                  {/* Sidebar */}

                  <section>
                    <h3>Services</h3>
                    <ul className="links">
                      <li><Link to="/services/">Overview</Link></li>
                      <li><Link to="/services/bill-pay-and-accounting/">Bill Pay and Accounting</Link></li>
                      <li><Link to="/services/income-tax-planning-and-compliance/">Income Tax Planning and Compliance</Link></li>
                      <li><Link to="/services/family-administrative-services/">Family Administrative Services</Link></li>
                      <li><Link to="/services/estate-and-gift-planning/">Estate and Gift Planning</Link></li>
                      <li><Link to="/services/philanthropic-consulting/">Philanthropic Consulting</Link></li>
                    </ul>
                  </section>

                </div>
              </div>
              <div className="col-8  col-12-narrower imp-narrower">
                <div id="content">

                  {/* Content */}
                  <article>
                    <header>
                      <h2>Income Tax Planning and Compliance</h2>
                      <p>
                        Our experience and proactive approach to tax planning will help minimize your tax burden and achieve your goals.
                      </p>
                    </header>

                    <span className="image featured">
                      <StaticImage
                        src="../../assets/images/GettyImages-169944220.jpg"
                        alt=""
                        width={725}
                      />
                    </span>

                    <p>
                    As an ultra-affluent individual, few services are as important as tax planning in achieving your goals. Preparedness and a full understanding of your total tax situation, as well as that of your closely held entities and businesses, is essential. Rosewood Family Advisors has committed material resources and assembled an experienced team of tax professionals to provide the necessary consulting in order to minimize your tax burden.
                    </p>
                    <p>
                    Our approach is to integrate tax planning ideas on a proactive versus reactive basis, utilizing the tax expertise of the respective team members. Tax laws are ever changing; it is imperative that we continue to monitor the issues as they relate to our ultra-affluent clients and the complexities of federal and state tax laws and regulations.
                    </p>
                    <p>
                    Rosewood Family Advisors offers the following income tax consulting and compliance services:
                    </p>
                    <ul>
                      <li>Preparation of tax returns, including related entities such as Trusts, S Corporations, Closely held C Corporations, Partnerships and Limited Liability Companies (“LLCs”).</li>
                      <li>Preparation of tax returns for charitable entities such as Private Foundations.</li>
                      <li>Transactional and forward looking income tax planning in order to minimize the payments to the various taxing authorities.</li>
                      <li>Representation on behalf of clients related to tax controversies with the various taxing authorities.</li>
                      <li>Proactive monitoring of new tax legislation and its effect on the client’s tax liabilities and future goals and objectives.</li>
                    </ul>
                  </article>

                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default InteriorPage;
